export default [
    {
        path: '/platform',
        name: 'platform',
        redirect:'/platform/orderManage',
        meta: {
            title: '平台',
            icon: 'el-icon-menu',
            permission:true
        },
        component: () => import ('@/views/platform'),
        children:[
            {
                path: '/platform/platformConfig',
                name: 'platform/platformConfig',
                meta: {
                    title: '平台配置',
                    icon: 'el-icon-menu',
                    permission:true,
                },
                component: () => import ('@/views/platform/platformConfig'),
                children:[
                    {
                        path: '/platform/platformConfig/measureConfig',
                        name: 'platform/platformConfig/measureConfig',
                        meta: {
                            title: '量尺价格配置',
                            icon: 'el-icon-menu',
                            permission:true,
                        },
                        component: () => import ('@/views/platform/platformConfig/measureConfig'),
                    },
                    {
                        path: '/platform/platformConfig/advConfig',
                        name: 'platform/platformConfig/advConfig',
                        meta: {
                            title: '广告图配置',
                            icon: 'el-icon-menu',
                            permission:true,
                        },
                        component: () => import ('@/views/platform/platformConfig/advConfig'),
                    },
                    {
                        path: '/platform/platformConfig/btnConfig',
                        name: 'platform/platformConfig/btnConfig',
                        meta: {
                            title: '客服按钮配置',
                            icon: 'el-icon-menu',
                            permission:true,
                        },
                        component: () => import ('@/views/platform/platformConfig/btnConfig'),
                    },
                    {
                        path: '/platform/platformConfig/agreementConfig',
                        name: 'platform/platformConfig/agreementConfig',
                        meta: {
                            title: '注册协议配置',
                            icon: 'el-icon-menu',
                            permission:true,
                        },
                        component: () => import ('@/views/platform/platformConfig/agreementConfig'),
                    },
                    {
                        path: '/platform/platformConfig/allowConfig',
                        name: 'platform/platformConfig/allowConfig',
                        meta: {
                            title: '关注引导配置',
                            icon: 'el-icon-menu',
                            permission:true,
                        },
                        component: () => import ('@/views/platform/platformConfig/allowConfig'),
                    },
                    {
                        path: '/platform/platformConfig/manageNotify',
                        name: 'platform/platformConfig/manageNotify',
                        meta: {
                            title: '管理员通知',
                            icon: 'el-icon-menu',
                            permission:true,
                        },
                        component: () => import ('@/views/platform/platformConfig/manageNotify'),
                    },
                    {
                        path: '/platform/platformConfig/manageConfig',
                        name: 'platform/platformConfig/manageConfig',
                        meta: {
                            title: '管理员配置（量尺）',
                            icon: 'el-icon-menu',
                            permission:true,
                        },
                        component: () => import ('@/views/platform/platformConfig/manageConfig'),
                    },
                    {
                        path: '/platform/platformConfig/logisticsManageConfig',
                        name: 'platform/platformConfig/logisticsManageConfig',
                        meta: {
                            title: '管理员配置（物流）',
                            icon: 'el-icon-menu',
                            permission:true,
                        },
                        component: () => import ('@/views/platform/platformConfig/logisticsManageConfig'),
                    },
                    {
                        path: '/platform/platformConfig/logisticsConfig',
                        name: 'platform/platformConfig/logisticsConfig',
                        meta: {
                            title: '物流配置',
                            icon: 'el-icon-menu',
                            permission:true,
                        },
                        component: () => import ('@/views/platform/platformConfig/logisticsConfig'),
                    }
                ]
            },
            {
                path: '/platform/orderManage',
                name: 'platform/orderManage',
                meta: {
                    title: '订单管理（量尺）',
                    icon: 'el-icon-menu',
                    permission:true
                },
                component: () => import ('@/views/platform/orderManage'),
            },
            {
                path: '/platform/orderLogisticsManage',
                name: 'platform/orderLogisticsManage',
                meta: {
                    title: '订单管理（物流）',
                    icon: 'el-icon-menu',
                    permission:true
                },
                component: () => import ('@/views/platform/orderLogisticsManage'),
            },
            {
                path: '/platform/logisticsAddress',
                name: 'platform/logisticsAddress',
                meta: {
                    title: '地址管理',
                    icon: 'el-icon-menu',
                    permission:true
                },
                component: () => import ('@/views/platform/logisticsAddress'),
            },
            {
                path: '/platform/shopManage',
                name: 'platform/shopManage',
                meta: {
                    title: '门店管理',
                    icon: 'el-icon-menu',
                    permission:true
                },
                component: () => import ('@/views/platform/shopManage'),
            },
            {
                path: '/platform/factoryManage',
                name: 'platform/factoryManage',
                meta: {
                    title: '工厂管理',
                    icon: 'el-icon-menu',
                    permission:true
                },
                component: () => import ('@/views/platform/factoryManage'),
            },
        ]
    }
]