import { createApp } from 'vue'
import App from './App.vue'

import '@/assets/js/flexible.js'

import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'

import router from './router';
import store from '@/store';

import ElementPlus from 'element-plus';

import dayjs from 'dayjs'

import 'element-plus/dist/index.css'
import '@/assets/css/common.scss'
import '@/assets/css/reset.css'

const app = createApp(App)
app.provide('dayjs', dayjs)
app.use(ElementPlus,{locale})
app.use(router)
app.use(store)
app.mount('#app')