export default [
    {
        path: '/factory',
        name: 'factory',
        redirect:'/factory/orderManage',
        meta: {
            title: '工厂',
            icon: 'el-icon-menu',
            permission:true
        },
        component: () => import ('@/views/factory'),
        children:[
            {
                path: '/factory/orderManage',
                name: 'factory/orderManage',
                meta: {
                    title: '订单管理',
                    icon: 'el-icon-menu',
                    permission:true
                },
                component: () => import ('@/views/factory/orderManage'),
            }
        ]
    }
]