import { createRouter, createWebHashHistory } from "vue-router";
import store from '@/store';

// 平台
import platform from './views/platform'
// 工厂
import factory from './views/factory'
// 门店
import shop from './views/shop'

const routes = [{
    path: '/',
    name: '',
    redirect: '/login',
    meta:{}
},{
    path: '/index',
    name: 'index',
    component: () => import ('@/views/index'),
    meta:{
        permission:true
    },
    // 菜单权限只计算children里面的内容
    children: [
        ...platform,
        ...factory,
        ...shop,
        {
            path: '/notPermission',
            name: 'notPermission',
            component: () => import ('@/views/notPermission'),
            meta:{
                title:'无权限',
                permission:true,
                hidden:true
            }
        }
    ]
},{
    path: '/login',
    name: 'login',
    component: () => import ('@/views/login'),
    meta:{
        permission:true
    }
},
{
    path: '/:pathMatch(.*)*',
    name: 'noFound',
    component: () => import ('@/views/noFound'),
    meta:{
        title:'页面不存在',
        permission:true,
        hidden:true
    }
}
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
    next()
    return;
    if(to.path == '/login'){
        next();
        return;
    }
    //非跳转到登录界面时，判断本地存储userData是否为null或空，为空则跳回到登录界面，反之到相应的界面(此时有数据)。
    let token = store.state.userData?store.state.userData.token:null;
    if (!token) {
        next(`/login?redirect=${to.path}`);
    } else {
        next();
    }
});


export default router