export default [
    {
        path: '/shop',
        name: 'shop',
        redirect:'/shop/orderManage',
        meta: {
            title: '门店',
            icon: 'el-icon-menu',
            permission:true
        },
        component: () => import ('@/views/shop'),
        children:[
            {
                path: '/shop/orderManage',
                name: 'shop/orderManage',
                meta: {
                    title: '订单管理',
                    icon: 'el-icon-menu',
                    permission:true
                },
                component: () => import ('@/views/shop/orderManage'),
            }
        ]
    }
]